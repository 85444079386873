import * as React from 'react';

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (
            <div className="footer">
            © HomeMoney™
          2007–{new Date().getFullYear()}
          <a href="https://homemoney.userecho.com/">Help</a>
          <a href="http://homemoney.ua/blog/">Blog</a>
          <a href="https://www.facebook.com/homemoneyua/">Facebook</a>
          <a href="https://homemoney.ua/terms.aspx">Terms</a>
          </div>
        );
    }
}