import * as React from 'react';
import { connect } from 'react-redux';
import Footer from '../Footer';
const Home = () => (
  <div className="main-landing">
    <div className="landing-top">

    <h1>Узнайте, на что уходят ваши деньги!</h1>
    <h1> <br/></h1>
    <h1>13 лет<br/>400 000 пользователей<br/>32 000 000 записей</h1>
    <h1> <br/></h1>
  
    HomeMoney ­— это мощная полнофункциональная система учёта личных финансов. Мы работаем с 2007 года. Есть мобильные приложения для всех платформ. Всё, что вам нужно для контроля личных финансов — уже здесь!
  
    </div>
    <h2>Всегда под рукой</h2>
    HomeMoney ­— это веб-приложение в браузере и мобильные приложения для iPhone и Android. Мобильными приложениями можно пользоваться одновременно — данные хранятся в облаке и синхронизируются при каждом подключении к интернету. Можно завести один аккаунт для всей семьи и вести общую домашнюю бухгалтерию.
    <h2>Интернет-банк уже здесь</h2>
    Получайте свежую выписку в один клик. Это примерно в 78 раз быстрее, чем вводить руками. При этом у вас есть полный контроль над процессом импорта. Наш импорт поддерживает
    <h2>Ценность в деталях</h2>
    При первом знакомстве HomeMoney покажется вам простым. Чтобы понять, на что уходят деньги, достаточно базовых фукций. Но при желании вы найдёте у нас множество полезных возможностей:
    <div className="features">

    <p className="feature">
      <h3>Валюты</h3>
      К каждому счёту можно привязать любое количество валют, система знает курс, и историю курсаза каждый день в прошлом. Включаю крипто-валюты BTC, ETH
    </p>
    <p className="feature">
    <h3>Счета</h3>
    Вы можете создать любое количество счетов и удобно объядинять их в группы
    </p>
    <p className="feature">
    <h3>Категории</h3>
    Создайте любое число категорий доходов и расходов, сгруппируйте их в подкатегории
    </p>
    <p className="feature">
    <h3>Запланированные операции</h3>
    Запись на будущее с пометкой "план" что бы не забыть
    </p>
    <p className="feature">
    <h3>Шаблоны записей</h3>
    Позволяют быстро добавть запись с одинаковой суммой и примечанием
    </p>
    <p className="feature">
    <h3>Записи по расписанию</h3>
    Шаблон записи по рассанию, каждый день, месяц, год
    </p>
    <p className="feature">
    <h3>Записи списком и на календаре</h3>
    Просмотр записей по счетам, категориям, на календаре
    </p>
    <p className="feature">
    <h3> Визуальный анализ</h3>
    Интерактивная инфографика, показывающая ровно то, что вас интересует в даный момент
    </p>
    <p className="feature">
    <h3>Продвинутые отчёты</h3>
    Исследуйте денежный поток, составляйте годовой баланс и годовой отчёт с помощью специальных инструментов
    </p>
    <p className="feature">
    </p>
    </div>
    <h2>Бюджет под контролем</h2>
    Запланируйте бюджет расходов или доходов на месяц и следите за его соблюдением. Скопируйте бюджет из прошлого месяца в один клик и внесите корррективы.
    <h2>Безопасность в приоритете</h2>
    Мы подумали о безопасности. И продолжаем думать о ней всё время: каждое нововведение оценивается в том числе с точки зрения рисков повреждения и несанкционированного доступа к данным.
   Мы используем шифрование данных с помощью протокола SSL. Мы храним данные в дата-центре Microsoft Azure в Амстердаме, а резервные копии создаются каждые два часа и хранятся в нескольких дата-центрах на территории США (Amazon's Simple Storage Service). И, конечно же, мы сами не передаём ваши данные третьим лицам.
   <h2>Отзывы пользователей</h2>
   <div className="userfeedback">
      <div className="box">
        Перепробовал несколько бесплатных домашних систем управления финансами, но только HomeMoney оказалась наиболее близка по функционалу к моим потребностям. Раньше пользовался оффлайновым Windows-приложением, который вообщем-то полностью меня устраивал, но не было в нем возможности вносить расходы с телефона и другого компьютера без установки приложения, что и сподвигло меня на исследование облачных сервисов. Большое спасибо разработчикам!
        <h4>Иван Бойко</h4>
      </div>
      <div className="box">
         Начал пользоваться вашим сервисом еще в 2008, когда откладывал деньги на авто. Очень помогло держать себя в руках и быть экономным. За полгода смог насобирать нужную сумму. Потом через какое-то время забросил ведение учета, но сейчас вот вернулся снова. Уровень сайта и функциональности очень вырос с того времени, очень много полезных нововведений. Отдельно стоит отметить мобильные сервисы, стало намного удобнее вести учет через телефон. Отличная работа, молодцы! С удовольствием плачу за премиум-аккаунт - он того стоит. :)
        <h4>Дядя Б</h4>
      </div>
      <div className="box">
        Спасибо огромное за сайт. Пользуюсь уже три года. Мне очень помогает вести свою домашнюю бухгалтерию. Подсадила на вашу программу 3 подруг. И они тогже быстро это дело осовили и с радостью пользуются. От всех нас спасибо
        <h4>Виктория</h4>
      </div>
      <div className="box">
        Мой опыт ведения домашней бухгалтерии насчитывает уже более 10 лет: начинал с Excel, потом был MS Money и клиент на PocketPC. Когда перешел на iPhone перепробовал кучу различных приложений, но идеала не было, пока не наткнулся на Ваш сервис. Пользуюсь Homemoney c Ноября 2011, с удовольствием оплатил Premium account. Очень нравится лаконичный продуманный интерфейс. Присутствует весь набор необходимых для домашней бухгалтерии возможностей: управление счетами и транзакциями, мультивалютность, бюджет и средства анализа. А теперь о двух фишках, которые я смог найти только у Вас и которые предопределили мой выбор: - Отчетность (о да!), у Вас я в первый раз нашел отчеты в том виде, в котором я сделал бы их сам для себя, 100% попадание и огромное отдельное спасибо! - Возможность вводить транзакции в клиенте с мобильного устройства, синхронизироваться с сервисом, а затем продолжать работу на стационарном компьютере... пожалуй в полном объеме такая возможность грамотно реализована только у Вас.
        <h4>Sergey Gomanyuk</h4>   
      </div>
      <div className="box">
        Долго выбирал между различными сервисами и программами, но Homemoney оказался самым удобным, динамично развивающимся с отличной поддержкой сервисом! Поддержка всех мобильных платформ, а также мультиязычность интерфейсов, несомненно привлечет и уже привлекает множество людей! Теперь стало очень удобно под одним счетом держать множество разных валют, а автоматическое обновление курса всегда поддерживает итоговый баланс актуальным.
        <h4>BrianFerry</h4>
      </div>   
      <div className="box">
        Когда-то давно уже работал с этой системой, причем несколько месяцев и очень даже удачно, но, к сожалению, забросил. Сегодня, год спустя, вернулся к учету финансов (хоть и целый год мобильное приложение было установлено на телефоне), да к тому же уже с пополнением в семье :) Однажды найдя ваш ресурс, выбрал его навсегда.
        <h4>Руслан</h4>
      </div>       
      <div className="box">
        Спасибо за полезный и удобный сервис, которым я, хоть и с перерывами, пользуюсь уже года полтора, и который приучил меня вести учет и более рационально подходить к тратам!
        <h4>Ольга</h4>
      </div>
  </div>
  <Footer></Footer>



    {/* <ul>
      <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
      <li><a href='https://facebook.github.io/react/'>React</a> and <a href='https://redux.js.org/'>Redux</a> for client-side code</li>
      <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
    </ul>
    <p>To help you get started, we have also set up:</p>
    <ul>
      <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
      <li><strong>Development server integration</strong>. In development mode, the development server from <strong>create-react-app</strong> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
      <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <strong>dotnet publish</strong> configuration produces minified, efficiently bundled JavaScript files.</li>
    </ul>
    <p>The <strong>ClientApp</strong> subdirectory is a standard React application based on the <strong>create-react-app</strong> template. If you open a command prompt in that directory, you can run <strong>npm</strong> commands such as <strong>npm test</strong> or <strong>npm install</strong>.</p> */}
  </div>
);

export default connect()(Home);
