import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import HomeEn from './components/en/Home';
import HomeRu from './components/ru/Home';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import Pricing from './components/Pricing';

import './custom.css'

export default () => (
    <Layout>
        <Route exact path='/' component={HomeRu} />
        <Route exact path='/en/' component={HomeEn} />
        <Route exact path='/ru/' component={HomeRu} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
        <Route path='/pricing' component={Pricing} />
        <Route path='/sign-in' component={Pricing} />
        <Route path='/sign-up' component={Pricing} />
    </Layout>
);
