import * as React from 'react';
import { connect } from 'react-redux';

const Home = () => (
  <div>
 
<h2>Цены</h2>
Старт
Премиум
Про
 </div>
);

export default connect()(Home);
